import custOfetch from '~~/composables/useFetch'
import type { AdminSettings } from '~/types/prisma'

const config = useRuntimeConfig()

export interface CreateAdminSettingDto {
  description: string
  status?: boolean
  value?: number
  date?: Date
}

export interface UpdateAdminSettingDto extends Partial<CreateAdminSettingDto> {}

export const useAdminSettings = () => {
  const getAll = async () => {
    return await custOfetch(`${config.public.apiBase}/admin-settings`, {
      method: 'GET',
    }) as AdminSettings[]
  }

  const create = async (
    payload: CreateAdminSettingDto,
  ) => {
    return await custOfetch(`${config.public.apiBase}/admin-settings`, {
      method: 'POST',
      body: payload,
    })
  }

  const getById = async (id: number) => {
    return await custOfetch(`${config.public.apiBase}/admin-settings/${id}`, {
      method: 'GET',
    }) as AdminSettings
  }

  const getByDescription = async (description: string) => {
    return await custOfetch(`${config.public.apiBase}/admin-settings/description/${description}`, {
      method: 'GET',
    }) as AdminSettings
  }

  const updateById = async (
    id: number,
    payload: UpdateAdminSettingDto,
  ) => {
    return await custOfetch(`${config.public.apiBase}/admin-settings/${id}`, {
      method: 'PATCH',
      body: payload,
    }) as AdminSettings
  }

  const deleteById = async (id: number) => {
    return await custOfetch(`${config.public.apiBase}/admin-settings/${id}`, {
      method: 'DELETE',
    })
  }

  return {
    create,
    getAll,
    getById,
    getByDescription,
    updateById,
    deleteById,
  }
}
